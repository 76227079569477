import Model from '@/application/models/model.js';

class DocumentType extends Model {
  id = 0;
  documentCategoryId = 0;
  name = '';
  hasExpirationDate = false;
  permissions = [];

  mapForRequest() {
    return {
      name: this.name,
      hasExpirationDate: this.hasExpirationDate,
      permissions: this.permissions,
    };
  }
}

export default DocumentType;
