<template>
  <PageLayout>
    <template #header>
      <k-title>{{ $tc('documentCategory.title', 2) }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        tile
        depressed
        color="primary"
        @click="createCategoryDialog = true"
      >
        {{ $t('actions.create') }}
      </v-btn>
      <DocumentCategoryForm
        v-model="createCategoryDialog"
        :request="createCategoryRequest"
        :title="$tc('documentCategory.title',1)"
        :values="createCategoryValues"
      />
    </template>
    <template #default>
      <v-row>
        <v-col lg="3">
          <v-card>
            <v-treeview
              :items="folders"
              activatable
              class="treeview-table"
              dense
              open-on-click
            >
              <template #append="{ item }">
                <template v-if="item.children">
                  <v-btn
                    icon
                    @click.stop="addType(item)"
                  >
                    <v-icon>$plus</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    @click.stop="updateCategory(item)"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="item.children.length === 0"
                    icon
                    @click.stop="deleteCategory(item)"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    icon
                    @click.stop="updateType(item)"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.documentsCount === 0"
                    icon
                    @click.stop="deleteType(item)"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </template>
              </template>
            </v-treeview>
          </v-card>
        </v-col>
      </v-row>

      <DocumentTypeForm
        v-model="createTypeDialog"
        :request="createTypeRequest"
        :title="$tc('documentType.title',1)"
        :values="createTypeValues"
        is-create-form
      />

      <DocumentTypeForm
        v-model="updateTypeDialog"
        :request="updateTypeRequest"
        :title="$tc('documentType.title',1)"
        :values="updateTypeValues"
      />

      <DocumentCategoryForm
        v-model="updateCategoryDialog"
        :request="updateCategoryRequest"
        :title="$tc('documentCategory.title',1)"
        :values="updateCategoryValues"
      />
    </template>
  </PageLayout>
</template>

<script>

import eventBus from '@/application/eventBus.ts';
import DocumentCategory from '@/application/models/DocumentCategory.js';
import DocumentType from '@/application/models/DocumentType.js';
import PageLayout from '@/components/layout/PageLayout.vue';
import DocumentCategoryForm from '@/modules/document/components/DocumentCategoryForm.vue';
import DocumentTypeForm from '@/modules/document/components/DocumentTypeForm.vue';
import {
  create as createCategory,
  index,
  remove as removeCategory,
  show as showCategory,
  update as updateCategory,
} from '@/modules/employer/api/employerDocumentCategory.js';
import {
  create as createType,
  remove as removeType,
  show as showType,
  update as updateType,
} from '@/modules/employer/api/employerDocumentType.js';
import '@/scss/treeviewTable.scss';
import KTitle from '@/components/layout/KTitle.vue';

export default {
  name: 'DocumentCategorySettings',
  components: {
    KTitle,
    DocumentTypeForm,
    DocumentCategoryForm,
    PageLayout,
  },
  data: () => ({
    folders: [],
    createCategoryValues: new DocumentCategory(),
    createCategoryDialog: false,
    updateCategoryValues: new DocumentCategory(),
    updateCategoryDialog: false,
    createTypeValues: new DocumentType(),
    createTypeDialog: false,
    updateTypeValues: new DocumentType(),
    updateTypeDialog: false,
  }),
  created() {
    this.fetchFolders();
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'settings.employer-document-category' },
        text: this.$tc('documentCategory.title', 2),
      },
    ]);
  },
  methods: {
    async createCategoryRequest() {
      const request = await createCategory(this.createCategoryValues);
      await this.fetchFolders();
      return request;
    },
    async updateCategoryRequest() {
      const request = await updateCategory(this.updateCategoryValues);
      await this.fetchFolders();
      return request;
    },
    async fetchFolders() {
      const response = await index();
      this.folders = response.data.data.map(category => {
        return {
          id: category.id,
          name: category.name,
          children: category.documentTypes.map(type => {
            return {
              id: `${category.id}.${type.id}`,
              typeId: type.id,
              name: type.name,
              documentsCount: type.documentsCount,
            };
          }),
        };
      });
    },
    async addType(item) {
      this.createTypeValues.documentCategoryId = item.id;
      this.createTypeDialog = true;
    },
    async createTypeRequest() {
      const request = await createType(this.createTypeValues.documentCategoryId, this.createTypeValues);
      await this.fetchFolders();
      return request;
    },
    async updateTypeRequest() {
      const request = await updateType(this.updateTypeValues);
      await this.fetchFolders();
      return request;
    },
    async updateCategory(item) {
      const request = await showCategory(item.id);
      this.updateCategoryValues.mapResponse(request.data.data);
      this.updateCategoryDialog = true;
    },
    async updateType(item) {
      const request = await showType(item.typeId);
      this.updateTypeValues.mapResponse(request.data.data);
      this.updateTypeDialog = true;
    },
    deleteType(item) {
      eventBus.$emit('confirm', {
        title: this.$t('actions.deleteConfirmation.title'),
        body: this.$t('actions.deleteConfirmation.body', { resource: this.$tc('documentType.title', 1) }),
        confirmCallback: async () => {
          await removeType(item.id);
          await this.fetchFolders();
        },
      });
    },
    deleteCategory(item) {
      eventBus.$emit('confirm', {
        title: this.$t('actions.deleteConfirmation.title'),
        body: this.$t('actions.deleteConfirmation.body', { resource: this.$tc('documentCategory.title', 1) }),
        confirmCallback: async () => {
          await removeCategory(item.id);
          await this.fetchFolders();
        },
      });
    },
  },
};
</script>
