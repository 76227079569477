<template>
  <k-field-group language-prefix="documentType.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <KTextField
          v-model="values.name"
          field="name"
          grid="col-sm-4 pr-2"
          required
        />
      </template>
      <template #panel.1>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left" />
              <th class="text-left">
                {{ $t('permissions.default') }}
              </th>
              <th class="text-left">
                {{ $t('permissions.show') }}
              </th>
              <th class="text-left">
                {{ $t('permissions.store') }}
              </th>
              <th class="text-left">
                {{ $t('permissions.update') }}
              </th>
              <th class="text-left">
                {{ $t('permissions.destroy') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="permission in values.permissions"
              :key="permission.roleId"
            >
              <td>{{ permission.roleName }}</td>
              <td>
                <VCheckbox
                  v-model="permission.isDefault"
                  @change="updateDefaults(permission)"
                />
              </td>
              <td>
                <VCheckbox
                  v-model="permission.canRead"
                  :disabled="permission.isDefault"
                />
              </td>
              <td>
                <VCheckbox
                  v-model="permission.canCreate"
                  :disabled="permission.isDefault"
                />
              </td>
              <td>
                <VCheckbox
                  v-model="permission.canUpdate"
                  :disabled="permission.isDefault"
                />
              </td>
              <td>
                <VCheckbox
                  v-model="permission.canDelete"
                  :disabled="permission.isDefault"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import { permissions } from '@/modules/employer/api/employerDocumentType.js';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'DocumentTypeForm',
  components: {
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    permissions: [],
  }),
  computed: {
    panels() {
      return [
        { name: this.$t('documentType.tabs.general') },
        { name: this.$t('documentType.tabs.permissions') },
      ];
    },
  },
  watch: {
    async '$attrs.value'(newValue, oldValue) {
      if (!newValue && oldValue) {
        return;
      } // Do nothing when a dialog is closed

      const permissions = await this.getPermissions();

      if (this.isCreateForm) {
        // Fill the permission values when there are no permissions set
        this.values.permissions = cloneDeep(permissions);
      }

      // Fill the default permission values when a dialog is opened
      this.permissions = permissions;
    },
  },
  methods: {
    async getPermissions() {
      const response = await permissions();
      return response.data.data;
    },
    updateDefaults(permission) {
      if (!permission.isDefault) {
        return;
      }
      const defaultPermission = this.permissions.find(obj => obj.roleId === permission.roleId);
      permission.canCreate = defaultPermission.canCreate;
      permission.canRead = defaultPermission.canRead;
      permission.canUpdate = defaultPermission.canUpdate;
      permission.canDelete = defaultPermission.canDelete;
    },
  },
};
</script>
