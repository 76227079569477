<template>
  <k-field-group language-prefix="documentCategory.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <KTextField
          v-model="values.name"
          field="name"
          grid="col-sm-4 pr-2"
          required
        />
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';

export default {
  name: 'DocumentCategoryForm',
  components: {
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
  },
  data: () => ({
    permissions: [],
  }),
  computed: {
    panels() {
      return [
        { name: this.$t('documentCategory.tabs.default') },
      ];
    },
  },
};
</script>
